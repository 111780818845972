import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTE_NAMES } from '../../Layouts/Unauthorized/interfaces'
import { routes } from '../../Layouts/Unauthorized/routes'
import { authorizedClient, unauthorizedClient } from '../../Lib/apolloClient'
import {
  ConfigureMfaDocument,
  IConfigureMfaPayload,
  LoginDocument,
  LogoutDocument,
  ReauthorizeDocument,
} from '../../Lib/graphql'
import { getSSOConfig, getToken, storageAccessTokenKey } from '../../Lib/jwtHelper'
import { TLocale } from '../../Lib/sharedInterfaces'
import { IAuthContext } from './interfaces'

export const AuthContext = React.createContext<IAuthContext>({
  login: (_email, _password, _remember, _otpToken, _redirectPath): Promise<boolean | void> => Promise.resolve(),
  configureMFA: (_email, _password, _remember, _otpToken): Promise<IConfigureMfaPayload | null> =>
    Promise.resolve(null),
  logout: (): Promise<void> => Promise.resolve(),
  reauthorizeWithToken: (_token: string): Promise<string> => Promise.resolve(''),
  reauthorizeWithRefreshToken: (): Promise<string> => Promise.resolve(''),
  loggedIn: false,
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const hasToken = !!getToken()
  const [loggedIn, setLoggedIn] = useState<boolean>(hasToken)
  const { children } = props
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const locale = i18n.language as TLocale

  // This needs to be here to make sure no old tokens remain in storage while switching to the new storage keys
  useEffect(() => {
    localStorage.removeItem('jwtToken')
    sessionStorage.removeItem('jwtToken')

    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('refreshToken')
  }, [])

  useEffect(() => {
    if (getToken()) setLoggedIn(true)
  }, [])

  const clearTokensFromStorage = (): void => {
    localStorage.removeItem(storageAccessTokenKey)
    sessionStorage.removeItem(storageAccessTokenKey)
  }

  const clearCurrentUser = useCallback((): void => {
    clearTokensFromStorage()
    setLoggedIn(false)
  }, [])

  const storeTokens = useCallback((storage: Storage, token: string) => {
    storage.setItem(storageAccessTokenKey, token)
  }, [])

  const logout = useCallback(async (): Promise<void> => {
    // clear tokens to prevent the logout call from breaking when the access token is invalid
    clearTokensFromStorage()

    const { link } = createNetworkStatusNotifier()
    const client = authorizedClient(link, locale)

    client
      .mutate({ mutation: LogoutDocument, variables: { input: {} } })
      .finally(clearCurrentUser)
      .catch((err) => {
        console.error('Logout mutation returned error:', err.message)
      })
  }, [clearCurrentUser, locale])

  const handleLoginSuccess = useCallback(
    (tokens: { accessToken: string }, remember?: boolean): void => {
      const redirectPath = sessionStorage.getItem('redirectPath')
      const storage = remember ? localStorage : sessionStorage

      storeTokens(storage, tokens.accessToken)
      setLoggedIn(true)

      window.history.replaceState({}, '')

      if (redirectPath) {
        navigate(redirectPath)
        sessionStorage.removeItem('redirectPath')
        return
      }

      navigate(routes[ROUTE_NAMES.LOGIN])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const login = useCallback(
    (
      email?: string,
      password?: string,
      remember?: boolean,
      otpToken?: string,
      redirectPath?: string
    ): Promise<boolean | void> => {
      clearCurrentUser()

      if (redirectPath) sessionStorage.setItem('redirectPath', redirectPath)

      const client = unauthorizedClient(locale)
      const variables = { email: email || '', password: password || '', otpToken }

      return client
        .mutate({ mutation: LoginDocument, variables })
        .then(({ data }) => {
          const { result, otpRequired, hasOtpConfigured, passwordResetRequired, tokens } = data.login

          if (tokens.accessToken) handleLoginSuccess(tokens, remember)
          else if (otpRequired) {
            const routeParams = { email, password, remember }

            if (hasOtpConfigured) navigate(routes[ROUTE_NAMES.MFA_LOGIN], { state: routeParams })
            else navigate(routes[ROUTE_NAMES.MFA_CONFIGURE], { state: routeParams })
          } else if (passwordResetRequired) {
            const resetPasswordToken = tokens?.resetToken
            const routeParams = { forcePasswordReset: true }

            navigate(
              { pathname: routes[ROUTE_NAMES.RESET_PASSWORD], search: `?token=${resetPasswordToken}` },
              { state: routeParams }
            )
          }

          return !result.error
        })
        .catch((err) => console.error('Login mutation returned error:', err.message))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearCurrentUser, handleLoginSuccess, locale]
  )

  const handleConfigureMfaSuccess = useCallback(
    (email: string, password: string, remember?: boolean, otpToken?: string) => {
      login(email, password, remember, otpToken).catch(() => {})
    },
    [login]
  )

  const configureMFA = useCallback(
    async (email: string, password: string, remember?: boolean, otpToken?: string): Promise<IConfigureMfaPayload> => {
      const client = unauthorizedClient(locale)
      const input = { email, password, validateOtpSecret: otpToken || null }

      return client
        .mutate({ mutation: ConfigureMfaDocument, variables: { input } })
        .then(({ data }) => {
          if (data.configureMfa.configurationSuccess) handleConfigureMfaSuccess(email, password, remember, otpToken)

          return data.configureMfa
        })
        .catch((err) => console.error('ConfigureMFA mutation returned error:', err.message))
    },
    [handleConfigureMfaSuccess, locale]
  )

  const reauthorize = useCallback(
    (token?: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        const onRefreshError = (): void => {
          // The user could be an SSO user, if so, set the host url (sso login url) here.
          // We set the url here already because the logout function clears the storageAccessTokenKey which is needed to retrieve this url.
          const ssoConfig = getSSOConfig()
          const url = ssoConfig?.host

          logout()
            .finally(() =>
              navigate({ pathname: routes[ROUTE_NAMES.SESSION_EXPIRED], ...(!!url && { search: `?url=${url}` }) })
            )
            .catch(() => {})
        }

        const client = unauthorizedClient(locale, onRefreshError)

        client
          .query({ query: ReauthorizeDocument, variables: { refreshToken: token } })
          .then(({ data }) => {
            const storage = sessionStorage.getItem(storageAccessTokenKey) ? sessionStorage : localStorage

            storeTokens(storage, data.reauthorize.accessToken)
            resolve(data.reauthorize.accessToken)
          })
          .catch(reject)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearCurrentUser, logout, locale]
  )

  const reauthorizeWithRefreshToken = useCallback((): Promise<string> => {
    return reauthorize()
  }, [reauthorize])

  const reauthorizeWithToken = useCallback(
    (token: string): Promise<string> => {
      clearCurrentUser()

      return reauthorize(token)
    },
    [clearCurrentUser, reauthorize]
  )

  const options = useMemo(() => {
    return {
      login,
      configureMFA,
      logout,
      loggedIn,
      reauthorizeWithToken,
      reauthorizeWithRefreshToken,
    }
  }, [login, configureMFA, logout, loggedIn, reauthorizeWithToken, reauthorizeWithRefreshToken])

  return <AuthContext.Provider value={options}>{children}</AuthContext.Provider>
}
