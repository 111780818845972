import { TLocale } from '../sharedInterfaces'

export const generateApolloClientHeaders = ({
  accessToken,
  locale,
}: {
  accessToken?: string | null
  locale: TLocale
}): Record<string, string> => {
  const isProduction = import.meta.env.MODE === 'production'

  return {
    ...(!isProduction ? { 'X-Requested-With': 'XMLHttpRequest' } : {}),
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    Accept: 'application/json; api-version=3',
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    'User-Selected-Language': locale,
  }
}
